import React, {useEffect} from "react";
import Styles from './styles.module.scss';
import {useDispatch, useSelector} from "react-redux";

import botLogo from '../../theme/assets/svg/honee_cabinet_bot_logo.svg';
import earnedIcon from '../../theme/assets/other/honee_cabinet_bot_earned_icon.png';
import {getBotSubscribeInfo} from "../../store/cabinet/action";
import {useTranslation} from "react-i18next";


const CabinetBotMain = () => {
    const dispatch = useDispatch();
    const { t } = useTranslation();

    const botMainData = useSelector(store => store.cabinet.botMainData);

    const statusHandler = (status) =>{
        switch (status){
            case 'inactive':
                return  t('Не активен')
            case 'active':
                return t('Активный')
            case 'stopped':
                return t('Остановлен')
            default:
                return t('Неизвестно')
        }
    }

    useEffect(()=>{
        if (botMainData){
            dispatch(getBotSubscribeInfo());
            // if (botMainData?.subscription?.subscription?.name === 'Pro'){
            //     dispatch(getCustomSettings())
            // }
        }
    },[botMainData, dispatch])

    return (
        <section className={`${Styles.container} ${Styles.honeeCard}`}>
            <div className={`${Styles.contentWrap} ${Styles.honeeCardBody}`}>
                <div className={`${Styles.botLogoWrap}`}>
                    <img src={botLogo} alt="honee 2.0"/>
                </div>
                <div className={`${Styles.contentBox}`}>
                    <div className={`${Styles.infoRow}`}>
                        <div className={`${Styles.infoRowNote}`}>
                            {t('HoneeAI 2.1 — это радикально обновленный и улучшенный искусственный интеллект от OMT')}.
                        </div>
                        <div className={`${Styles.infoRowSubscription}`}>
                            <div className={`${Styles.infoRowSubscriptionName}`}>{t('Подписка')}:</div>
                            <div className={`${Styles.infoRowSubscriptionValue}`}>{botMainData?.subscription?.status === 'pending' ? 'Pending' : botMainData?.subscription?.subscription_type ? botMainData?.subscription?.subscription_type === 'ProPlus' ? 'Pro+' : botMainData?.subscription?.subscription_type : '--'}</div>
                        </div>
                        <div className={`${Styles.infoRowSubscription}`}>
                            <div className={`${Styles.infoRowSubscriptionName}`}>{t('Подписĸа истеĸает')}:</div>
                            <div className={`${Styles.infoRowSubscriptionValue}`}>{botMainData?.subscription?.date_to ? botMainData?.subscription?.date_to : '--'}</div>
                        </div>
                    </div>
                    <div className={`${Styles.earnedRow}`}>
                        <div className={`${Styles.titleWSF}`}>{t('Заработано')}:</div>
                        <div className={`${Styles.earnedRowTotal}`}>
                            <div className={`${Styles.imgWrap}`}>
                                <img src={earnedIcon} alt="Earned:"/>
                            </div>
                            <div className={`${Styles.total}`}>{botMainData?.bot?.profit ? botMainData?.bot?.profit : '0'}</div>
                        </div>
                        <div className={`${Styles.earnedRowStatus}`}>
                            <div className={`${Styles.earnedRowStatusName}`}>{t('Уровень')}:</div>
                            <div className={`${Styles.earnedRowStatusValue}`}>{botMainData?.bot?.level ? Number(botMainData?.bot?.level) : '0'}</div>
                        </div>
                        <div className={`${Styles.earnedRowStatus}`}>
                            <div className={`${Styles.earnedRowStatusName}`}>{t('Статус')}:</div>
                            <div className={`${Styles.earnedRowStatusValue}`}>{botMainData?.bot?.status ? statusHandler(botMainData?.bot?.status) : ''}</div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default CabinetBotMain;