import React from "react";
import Styles from './styles.module.scss'
import {useTranslation} from "react-i18next";
import {useSelector} from "react-redux";
import {useNavigate} from "react-router-dom";




const AcademyLessonsPreview = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const academyAllLessons = useSelector(store => store.cabinet.academyAllLessons);
    const academyMainData = useSelector(store => store.cabinet.academyMainData);

    const statusTextHandler = (status) =>{
        switch (status){
            case 'active':
                return t('Доступен');
            case 'inactive':
                return t('Не доступен');
            case 'completed':
                return t('Пройден');
            default:
                return t('Не доступен');
        }
    }

    const lessonLinkHandler = (status, id) =>{
        if (status !== 'inactive'){
            navigate(`/academy/${academyMainData?.course_id}/${id}`)
        }
    }

    return (
        <section className={`${Styles.container} ${Styles.honeeCard}`}>
            <div className={`${Styles.honeeCardBody}`}>
                <div className={`${Styles.lessonsWrap}`}>
                    {academyAllLessons ? academyAllLessons.map((item, index)=>{
                        return (
                            <div
                                className={`${Styles.lessonsItem} ${item.status === 'active' ? Styles.lessonsItemActive : null} ${item.status === 'inactive' ? Styles.lessonsItemInActive : null} ${item.status === 'completed' ? Styles.lessonsItemCompleted : null}`}
                                key={'lessonPreview' + index}
                                style={{backgroundImage: `url('${item.picture}')`}}
                                onClick={()=>{lessonLinkHandler(item.status, item.id)}}
                            >
                                <div className={`${Styles.lessonsItemContent}`}>
                                    {item.order !== 1 ?
                                        <div className={`${Styles.lessonsItemContentNumber} ${Styles.titleWSF}`}>{t('Урок')} {item.order}.</div> :
                                        <div className={`${Styles.lessonsItemContentNumber} ${Styles.titleWSF}`}>{t('Вводный урок')}.</div>
                                    }
                                    <div className={`${Styles.lessonsItemContentTitle} ${Styles.titleWSF}`}>{item.title}</div>
                                </div>
                                <div className={`${Styles.lessonsAbsInfo}`}>
                                    <div className={`${Styles.lessonsAbsInfoStatusWrap}`}>
                                        <div className={`${Styles.lessonsAbsInfoStatusWrapName}`}>{t('Статус')}:</div>
                                        <div className={`${Styles.lessonsAbsInfoStatusWrapValue}`}>{statusTextHandler(item.status)}</div>
                                    </div>
                                    {item.questions_exists ?
                                        <div className={`${Styles.lessonsAbsInfoPointWrap}`}>
                                            <div className={`${Styles.lessonsAbsInfoPointWrapName}`}>{t('Оценка')}:</div>
                                            <div className={`${Styles.lessonsAbsInfoPointWrapValue}`}>{item.points ? item.points : item.points === 0 ? '0' : '-'}</div>
                                        </div> : null
                                    }
                                </div>
                                <div className={`${Styles.lessonsAbsShade}`}/>
                            </div>
                        )
                    }) : null}
                </div>
            </div>
        </section>
    );
};

export default AcademyLessonsPreview;
