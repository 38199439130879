import React from "react";
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';
import Styles from './styles.module.scss'
import {useDispatch, useSelector} from "react-redux";
import {
    getAcademyCurrentLesson,
    getAcademyTestQuestion,
    setAcademyTestWarningModalStatus
} from "../../store/cabinet/action";

import {useTranslation} from "react-i18next";
import warningIcon from '../../theme/assets/svg/h2_academy_warning_icon.svg';
import {useParams} from "react-router-dom";


const AcademyTestWarningModal = () => {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    let {courceId, lessonId} = useParams();

    const academyTestWarningModalStatus = useSelector(store => store.cabinet.academyTestWarningModalStatus);

    const closeModal = () =>{
        dispatch(setAcademyTestWarningModalStatus(false))
    }

    const startTestHandler = () =>{
        const successHandler = () =>{
            dispatch(getAcademyCurrentLesson(courceId, lessonId));
        }
        dispatch(getAcademyTestQuestion(courceId, lessonId, successHandler));
        closeModal();
    }

    return (
        <Popup
            open={academyTestWarningModalStatus}
            modal
            nested
            closeOnDocumentClick
            onClose={closeModal}
        >
            <div className={`${Styles.modalContainer}`}>
                <div className={`${Styles.modalCloseBtn}`} onClick={()=>{closeModal()}}>
                    <div/>
                    <div/>
                </div>
                <div className={`${Styles.modalContentWrap}`}>
                    <div className={`${Styles.iconWrap}`}>
                        <img src={warningIcon} alt={t('ВНИМАНИЕ!')}/>
                    </div>
                    <div className={`${Styles.warningTitle}`}>{t('ВНИМАНИЕ!')}</div>
                    <div className={`${Styles.warningNote}`}>{t('У вас есть только одна попытка пройти финальный тест. Будьте внимательны и тщательно обдумывайте каждый ответ. После завершения повторное прохождение будет недоступно. Удачи!')}</div>
                    <div className={`${Styles.startBtn} ${Styles.btn}`} onClick={()=>{startTestHandler()}}>{t('Начать тест')}</div>
                </div>
            </div>
        </Popup>
    );
};

export default AcademyTestWarningModal;
