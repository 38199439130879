export const TEST = 'TEST';
export const SET_MOBILE_NAV_STATUS = 'SET_MOBILE_NAV_STATUS';
export const SET_BOT_SUBSCRIBE_MODAL_STATUS = 'SET_BOT_SUBSCRIBE_MODAL_STATUS';
export const SET_BONUSES_MODAL_STATUS = 'SET_BONUSES_MODAL_STATUS';
export const SET_REF_LOGS_CORRECT_DATA = 'SET_REF_LOGS_CORRECT_DATA';
export const SET_REF_LOGS_PAGE_DATA = 'SET_REF_LOGS_PAGE_DATA';
export const SET_INFLUENCER_MODAL_STATUS = 'SET_INFLUENCER_MODAL_STATUS';
export const SET_BOT_PROMO_MODAL_STATUS = 'SET_BOT_PROMO_MODAL_STATUS';
export const SET_EVENT_NY_TASK_MODAL_STATUS = 'SET_EVENT_NY_TASK_MODAL_STATUS';
export const SET_EVENT_NY_WIN_MODAL_STATUS = 'SET_EVENT_NY_WIN_MODAL_STATUS';
export const SET_EVENT_NY_HISTORY_MODAL_STATUS = 'SET_EVENT_NY_HISTORY_MODAL_STATUS';
export const SET_EVENT_NY_BUY_COINS_MODAL_STATUS = 'SET_EVENT_NY_BUY_COINS_MODAL_STATUS';

export const GET_BOT_MAIN_DATA_STARTED = 'GET_BOT_MAIN_DATA_STARTED';
export const GET_BOT_MAIN_DATA_SUCCESS = 'GET_BOT_MAIN_DATA_SUCCESS';
export const GET_BOT_MAIN_DATA_FAILURE = 'GET_BOT_MAIN_DATA_FAILURE';

export const SEND_SUPPORT_FORM_STARTED = 'SEND_SUPPORT_FORM_STARTED';
export const SEND_SUPPORT_FORM_SUCCESS = 'SEND_SUPPORT_FORM_SUCCESS';
export const SEND_SUPPORT_FORM_FAILURE = 'SEND_SUPPORT_FORM_FAILURE';

export const GET_BOT_SUBSCRIBE_INFO_STARTED = 'GET_BOT_SUBSCRIBE_INFO_STARTED';
export const GET_BOT_SUBSCRIBE_INFO_SUCCESS = 'GET_BOT_SUBSCRIBE_INFO_SUCCESS';
export const GET_BOT_SUBSCRIBE_INFO_FAILURE = 'GET_BOT_SUBSCRIBE_INFO_FAILURE';

export const GET_REF_MAIN_DATA_STARTED = 'GET_REF_MAIN_DATA_STARTED';
export const GET_REF_MAIN_DATA_SUCCESS = 'GET_REF_MAIN_DATA_SUCCESS';
export const GET_REF_MAIN_DATA_FAILURE = 'GET_REF_MAIN_DATA_FAILURE';

export const GET_REF_INFO_STARTED = 'GET_REF_INFO_STARTED';
export const GET_REF_INFO_SUCCESS = 'GET_REF_INFO_SUCCESS';
export const GET_REF_INFO_FAILURE = 'GET_REF_INFO_FAILURE';

export const GET_REF_LOGS_STARTED = 'GET_REF_LOGS_STARTED';
export const GET_REF_LOGS_SUCCESS = 'GET_REF_LOGS_SUCCESS';
export const GET_REF_LOGS_FAILURE = 'GET_REF_LOGS_FAILURE';

export const GET_AMBASSADOR_MAIN_DATA_STARTED = 'GET_AMBASSADOR_MAIN_DATA_STARTED';
export const GET_AMBASSADOR_MAIN_DATA_SUCCESS = 'GET_AMBASSADOR_MAIN_DATA_SUCCESS';
export const GET_AMBASSADOR_MAIN_DATA_FAILURE = 'GET_AMBASSADOR_MAIN_DATA_FAILURE';

export const GET_AMBASSADOR_STATUSES_STARTED = 'GET_AMBASSADOR_STATUSES_STARTED';
export const GET_AMBASSADOR_STATUSES_SUCCESS = 'GET_AMBASSADOR_STATUSES_SUCCESS';
export const GET_AMBASSADOR_STATUSES_FAILURE = 'GET_AMBASSADOR_STATUSES_FAILURE';

export const GET_AMBASSADOR_GRAPH_STARTED = 'GET_AMBASSADOR_GRAPH_STARTED';
export const GET_AMBASSADOR_GRAPH_SUCCESS = 'GET_AMBASSADOR_GRAPH_SUCCESS';
export const GET_AMBASSADOR_GRAPH_FAILURE = 'GET_AMBASSADOR_GRAPH_FAILURE';
export const GET_AMBASSADOR_GRAPH_CORRECT_DATA = 'GET_AMBASSADOR_GRAPH_CORRECT_DATA';

export const GET_AMBASSADOR_TURNOVER_STARTED = 'GET_AMBASSADOR_TURNOVER_STARTED';
export const GET_AMBASSADOR_TURNOVER_SUCCESS = 'GET_AMBASSADOR_TURNOVER_SUCCESS';
export const GET_AMBASSADOR_TURNOVER_FAILURE = 'GET_AMBASSADOR_TURNOVER_FAILURE';

export const GET_AMBASSADOR_LINKS_STARTED = 'GET_AMBASSADOR_LINKS_STARTED';
export const GET_AMBASSADOR_LINKS_SUCCESS = 'GET_AMBASSADOR_LINKS_SUCCESS';
export const GET_AMBASSADOR_LINKS_FAILURE = 'GET_AMBASSADOR_LINKS_FAILURE';
export const AMBASSADOR_CORRECT_LINKS = 'AMBASSADOR_CORRECT_LINKS';

export const SEND_AMBASSADOR_LINK_STARTED = 'SEND_AMBASSADOR_LINK_STARTED';
export const SEND_AMBASSADOR_LINK_SUCCESS = 'SEND_AMBASSADOR_LINK_SUCCESS';
export const SEND_AMBASSADOR_LINK_FAILURE = 'SEND_AMBASSADOR_LINK_FAILURE';

export const AMBASSADOR_LINK_ERRORS = 'AMBASSADOR_LINK_ERRORS';
export const AMBASSADOR_LINK_ERRORS_CLEAR = 'AMBASSADOR_LINK_ERRORS_CLEAR';
export const SET_AMBASSADORS_MODAL_STATUS = 'SET_AMBASSADORS_MODAL_STATUS';
export const SET_AMBASSADORS_MODAL_SALARY_STATUS = 'SET_AMBASSADORS_MODAL_SALARY_STATUS';

export const GET_AMBASSADOR_SALARY_DATA_STARTED = 'GET_AMBASSADOR_SALARY_DATA_STARTED';
export const GET_AMBASSADOR_SALARY_DATA_SUCCESS = 'GET_AMBASSADOR_SALARY_DATA_SUCCESS';
export const GET_AMBASSADOR_SALARY_DATA_FAILURE = 'GET_AMBASSADOR_SALARY_DATA_FAILURE';

export const CHANGE_PASSWORD_STARTED = 'CHANGE_PASSWORD_STARTED';
export const CHANGE_PASSWORD_SUCCESS = 'CHANGE_PASSWORD_SUCCESS';
export const CHANGE_PASSWORD_FAILURE = 'CHANGE_PASSWORD_FAILURE';

export const GET_HONEECHIZE_MAIN_DATA_STARTED = 'GET_HONEECHIZE_MAIN_DATA_STARTED';
export const GET_HONEECHIZE_MAIN_DATA_SUCCESS = 'GET_HONEECHIZE_MAIN_DATA_SUCCESS';
export const GET_HONEECHIZE_MAIN_DATA_FAILURE = 'GET_HONEECHIZE_MAIN_DATA_FAILURE';

export const GET_HONEECHIZE_EARNINGS_GRAPH_DATA_STARTED = 'GET_HONEECHIZE_EARNINGS_GRAPH_DATA_STARTED';
export const GET_HONEECHIZE_EARNINGS_GRAPH_DATA_SUCCESS = 'GET_HONEECHIZE_EARNINGS_GRAPH_DATA_SUCCESS';
export const GET_HONEECHIZE_EARNINGS_GRAPH_DATA_FAILURE = 'GET_HONEECHIZE_EARNINGS_GRAPH_DATA_FAILURE';

export const GET_HONEECHIZE_PROFIT_GRAPH_DATA_STARTED = 'GET_HONEECHIZE_PROFIT_GRAPH_DATA_STARTED';
export const GET_HONEECHIZE_PROFIT_GRAPH_DATA_SUCCESS = 'GET_HONEECHIZE_PROFIT_GRAPH_DATA_SUCCESS';
export const GET_HONEECHIZE_PROFIT_GRAPH_DATA_FAILURE = 'GET_HONEECHIZE_PROFIT_GRAPH_DATA_FAILURE';

export const GET_HONEECHIZE_AFFILIATE_SETTINGS_STARTED = 'GET_HONEECHIZE_AFFILIATE_SETTINGS_STARTED';
export const GET_HONEECHIZE_AFFILIATE_SETTINGS_SUCCESS = 'GET_HONEECHIZE_AFFILIATE_SETTINGS_SUCCESS';
export const GET_HONEECHIZE_AFFILIATE_SETTINGS_FAILURE = 'GET_HONEECHIZE_AFFILIATE_SETTINGS_FAILURE';

export const GET_HONEECHIZE_BOT_EARNINGS_STARTED = 'GET_HONEECHIZE_BOT_EARNINGS_STARTED';
export const GET_HONEECHIZE_BOT_EARNINGS_SUCCESS = 'GET_HONEECHIZE_BOT_EARNINGS_SUCCESS';
export const GET_HONEECHIZE_BOT_EARNINGS_FAILURE = 'GET_HONEECHIZE_BOT_EARNINGS_FAILURE';

export const GET_HONEECHIZE_BOT_SETTINGS_STARTED = 'GET_HONEECHIZE_BOT_SETTINGS_STARTED';
export const GET_HONEECHIZE_BOT_SETTINGS_SUCCESS = 'GET_HONEECHIZE_BOT_SETTINGS_SUCCESS';
export const GET_HONEECHIZE_BOT_SETTINGS_FAILURE = 'GET_HONEECHIZE_BOT_SETTINGS_FAILURE';

export const SET_HONEECHIZE_BOT_SETTINGS_STARTED = 'SET_HONEECHIZE_BOT_SETTINGS_STARTED';
export const SET_HONEECHIZE_BOT_SETTINGS_SUCCESS = 'SET_HONEECHIZE_BOT_SETTINGS_SUCCESS';
export const SET_HONEECHIZE_BOT_SETTINGS_FAILURE = 'SET_HONEECHIZE_BOT_SETTINGS_FAILURE';

export const CHANGE_HONEECHIZE_AFFILIATE_PERCENT_STARTED = 'CHANGE_HONEECHIZE_AFFILIATE_PERCENT_STARTED';
export const CHANGE_HONEECHIZE_AFFILIATE_PERCENT_SUCCESS = 'CHANGE_HONEECHIZE_AFFILIATE_PERCENT_SUCCESS';
export const CHANGE_HONEECHIZE_AFFILIATE_PERCENT_FAILURE = 'CHANGE_HONEECHIZE_AFFILIATE_PERCENT_FAILURE';

export const GET_HONEECHIZE_AFFILIATE_STATISTIC_STARTED = 'GET_HONEECHIZE_AFFILIATE_STATISTIC_STARTED';
export const GET_HONEECHIZE_AFFILIATE_STATISTIC_SUCCESS = 'GET_HONEECHIZE_AFFILIATE_STATISTIC_SUCCESS';
export const GET_HONEECHIZE_AFFILIATE_STATISTIC_FAILURE = 'GET_HONEECHIZE_AFFILIATE_STATISTIC_FAILURE';

export const SET_HONEECHIZE_AFFILIATE_STATISTIC_PAGE_DATA = 'SET_HONEECHIZE_AFFILIATE_STATISTIC_PAGE_DATA';
export const SET_HONEECHIZE_AFFILIATE_STATISTIC_CORRECT_DATA = 'SET_HONEECHIZE_AFFILIATE_STATISTIC_CORRECT_DATA';
export const SET_HONEECHIZE_ALERT_MODAL_STATUS = 'SET_HONEECHIZE_ALERT_MODAL_STATUS';
export const SET_HONEECHIZE_AFFILIATE_SETTINGS_MODAL_STATUS = 'SET_HONEECHIZE_AFFILIATE_SETTINGS_MODAL_STATUS';
export const SET_HONEECHIZE_AFFILIATE_TABLE_MODAL_STATUS = 'SET_HONEECHIZE_AFFILIATE_TABLE_MODAL_STATUS';

export const GET_CUSTOM_SETTINGS_STARTED = 'GET_CUSTOM_SETTINGS_STARTED';
export const GET_CUSTOM_SETTINGS_SUCCESS = 'GET_CUSTOM_SETTINGS_SUCCESS';
export const GET_CUSTOM_SETTINGS_FAILURE = 'GET_CUSTOM_SETTINGS_FAILURE';

export const GET_INDEX_MAIN_DATA_STARTED = 'GET_INDEX_MAIN_DATA_STARTED';
export const GET_INDEX_MAIN_DATA_SUCCESS = 'GET_INDEX_MAIN_DATA_SUCCESS';
export const GET_INDEX_MAIN_DATA_FAILURE = 'GET_INDEX_MAIN_DATA_FAILURE';

export const INDEX_SWAP_STARTED = 'INDEX_SWAP_STARTED';
export const INDEX_SWAP_SUCCESS = 'INDEX_SWAP_SUCCESS';
export const INDEX_SWAP_FAILURE = 'INDEX_SWAP_FAILURE';

export const GET_INDEX_SWAP_HISTORY_STARTED = 'GET_INDEX_SWAP_HISTORY_STARTED';
export const GET_INDEX_SWAP_HISTORY_SUCCESS = 'GET_INDEX_SWAP_HISTORY_SUCCESS';
export const GET_INDEX_SWAP_HISTORY_FAILURE = 'GET_INDEX_SWAP_HISTORY_FAILURE';

export const GET_INDEX_SWAP_PREDICTION_STARTED = 'GET_INDEX_SWAP_PREDICTION_STARTED';
export const GET_INDEX_SWAP_PREDICTION_SUCCESS = 'GET_INDEX_SWAP_PREDICTION_SUCCESS';
export const GET_INDEX_SWAP_PREDICTION_FAILURE = 'GET_INDEX_SWAP_PREDICTION_FAILURE';

export const CHANGE_BOT_RISK_STARTED = 'CHANGE_BOT_RISK_STARTED';
export const CHANGE_BOT_RISK_SUCCESS = 'CHANGE_BOT_RISK_SUCCESS';
export const CHANGE_BOT_RISK_FAILURE = 'CHANGE_BOT_RISK_FAILURE';

export const BOT_INVEST_STARTED = 'BOT_INVEST_STARTED';
export const BOT_INVEST_SUCCESS = 'BOT_INVEST_SUCCESS';
export const BOT_INVEST_FAILURE = 'BOT_INVEST_FAILURE';

export const OPEN_BOT_STARTED = 'OPEN_BOT_STARTED';
export const OPEN_BOT_SUCCESS = 'OPEN_BOT_SUCCESS';
export const OPEN_BOT_FAILURE = 'OPEN_BOT_FAILURE';

export const CONTROL_BOT_STARTED = 'OPEN_BOT_STARTED';
export const CONTROL_BOT_SUCCESS = 'OPEN_BOT_SUCCESS';
export const CONTROL_BOT_FAILURE = 'OPEN_BOT_FAILURE';

export const SET_BOT_REINVEST_STARTED = 'SET_BOT_REINVEST_STARTED';
export const SET_BOT_REINVEST_SUCCESS = 'SET_BOT_REINVEST_SUCCESS';
export const SET_BOT_REINVEST_FAILURE = 'SET_BOT_REINVEST_FAILURE';

export const GET_BOT_HISTORY_STARTED = 'GET_BOT_HISTORY_STARTED';
export const GET_BOT_HISTORY_SUCCESS = 'GET_BOT_HISTORY_SUCCESS';
export const GET_BOT_HISTORY_FAILURE = 'GET_BOT_HISTORY_FAILURE';

export const SET_STANDARD_WALLET_CRYPTO_MODAL_STATUS = 'SET_STANDARD_WALLET_CRYPTO_MODAL_STATUS';
export const SET_CREATE_WALLET_CURRENCIES = 'SET_CREATE_WALLET_CURRENCIES';
export const SET_WALLET_CREATE_FORM_STATUS = 'SET_WALLET_CREATE_FORM_STATUS';

export const GET_INDICATORS_STARTED = 'GET_INDICATORS_STARTED';
export const GET_INDICATORS_SUCCESS = 'GET_INDICATORS_SUCCESS';
export const GET_INDICATORS_FAILURE = 'GET_INDICATORS_FAILURE';

export const GET_CRYPTO_NEWS_STARTED = 'GET_CRYPTO_NEWS_STARTED';
export const GET_CRYPTO_NEWS_SUCCESS = 'GET_CRYPTO_NEWS_SUCCESS';
export const GET_CRYPTO_NEWS_FAILURE = 'GET_CRYPTO_NEWS_FAILURE';

export const GET_AVAILABLE_CURRENCIES_STARTED = 'GET_AVAILABLE_CURRENCIES_STARTED';
export const GET_AVAILABLE_CURRENCIES_SUCCESS = 'GET_AVAILABLE_CURRENCIES_SUCCESS';
export const GET_AVAILABLE_CURRENCIES_FAILURE = 'GET_AVAILABLE_CURRENCIES_FAILURE';

export const CREATE_WALLET_STARTED = 'CREATE_WALLET_STARTED';
export const CREATE_WALLET_SUCCESS = 'CREATE_WALLET_SUCCESS';
export const CREATE_WALLET_FAILURE = 'CREATE_WALLET_FAILURE';

export const GET_WALLET_ASSETS_STARTED = 'GET_WALLET_ASSETS_STARTED';
export const GET_WALLET_ASSETS_SUCCESS = 'GET_WALLET_ASSETS_SUCCESS';
export const GET_WALLET_ASSETS_FAILURE = 'GET_WALLET_ASSETS_FAILURE';

export const GET_WALLET_ASSETS_WITH_BALANCE = 'GET_WALLET_ASSETS_WITH_BALANCE';
export const GET_REFILLABLE_WALLETS = 'GET_REFILLABLE_WALLETS';
export const SET_CURRENT_WALLET = 'SET_CURRENT_WALLET';

export const CREATE_CURRENT_WALLET_STARTED = 'CREATE_CURRENT_WALLET_STARTED';
export const CREATE_CURRENT_WALLET_SUCCESS = 'CREATE_CURRENT_WALLET_SUCCESS';
export const CREATE_CURRENT_WALLET_FAILURE = 'CREATE_CURRENT_WALLET_FAILURE';

export const SET_WALLET_TAKE_FORM_STATUS = 'SET_WALLET_TAKE_FORM_STATUS';
export const SET_WALLET_SEND_FORM_STATUS = 'SET_WALLET_SEND_FORM_STATUS';

export const GET_TRANSFER_COMMISSION_STARTED = 'GET_TRANSFER_COMMISSION_STARTED';
export const GET_TRANSFER_COMMISSION_SUCCESS = 'GET_TRANSFER_COMMISSION_SUCCESS';
export const GET_TRANSFER_COMMISSION_FAILURE = 'GET_TRANSFER_COMMISSION_FAILURE';

export const SEND_TRANSACTIONS_STARTED = 'SEND_TRANSACTIONS_STARTED';
export const SEND_TRANSACTIONS_SUCCESS = 'SEND_TRANSACTIONS_SUCCESS';
export const SEND_TRANSACTIONS_FAILURE = 'SEND_TRANSACTIONS_FAILURE';

export const GET_WALLET_GRAPH_HISTORY_STARTED = 'GET_WALLET_GRAPH_HISTORY_STARTED';
export const GET_WALLET_GRAPH_HISTORY_SUCCESS = 'GET_WALLET_GRAPH_HISTORY_SUCCESS';
export const GET_WALLET_GRAPH_HISTORY_FAILURE = 'GET_WALLET_GRAPH_HISTORY_FAILURE';

export const GET_OMT_PERIODS_STARTED = 'GET_OMT_PERIODS_STARTED';
export const GET_OMT_PERIODS_SUCCESS = 'GET_OMT_PERIODS_SUCCESS';
export const GET_OMT_PERIODS_FAILURE = 'GET_OMT_PERIODS_FAILURE';

export const GET_AVAILABLE_WALLETS_STARTED = 'GET_AVAILABLE_WALLETS_STARTED';
export const GET_AVAILABLE_WALLETS_SUCCESS = 'GET_AVAILABLE_WALLETS_SUCCESS';
export const GET_AVAILABLE_WALLETS_FAILURE = 'GET_AVAILABLE_WALLETS_FAILURE';

export const GET_CURRENT_WALLET_STARTED = 'GET_CURRENT_WALLET_STARTED';
export const GET_CURRENT_WALLET_SUCCESS = 'GET_CURRENT_WALLET_SUCCESS';
export const GET_CURRENT_WALLET_FAILURE = 'GET_CURRENT_WALLET_FAILURE';

export const GET_CURRENT_WALLET_TRANSACTIONS_STARTED = 'GET_CURRENT_WALLET_TRANSACTIONS_STARTED';
export const GET_CURRENT_WALLET_TRANSACTIONS_SUCCESS = 'GET_CURRENT_WALLET_TRANSACTIONS_SUCCESS';
export const GET_CURRENT_WALLET_TRANSACTIONS_FAILURE = 'GET_CURRENT_WALLET_TRANSACTIONS_FAILURE';

export const SET_STAKING_FORM_STATUS = 'SET_STAKING_FORM_STATUS';
export const SET_STAKING_BALANCE_STATUS = 'SET_STAKING_BALANCE_STATUS';
export const SET_STAKING_FORM_OMT_STATUS = 'SET_STAKING_FORM_OMT_STATUS';
export const SET_STAKING_WITHDRAW_FORM_STATUS = 'SET_STAKING_WITHDRAW_FORM_STATUS';
export const SET_STAKING_CLOSE_FORM_STATUS = 'SET_STAKING_CLOSE_FORM_STATUS';
export const SET_BOT_MODAL_STATUS = 'SET_BOT_MODAL_STATUS';

export const BALANCE_TRANSACTIONS_STARTED = 'BALANCE_TRANSACTIONS_STARTED';
export const BALANCE_TRANSACTIONS_SUCCESS = 'BALANCE_TRANSACTIONS_SUCCESS';
export const BALANCE_TRANSACTIONS_FAILURE = 'BALANCE_TRANSACTIONS_FAILURE';

export const GET_ONE_CURRENT_WALLET_STARTED = 'GET_ONE_CURRENT_WALLET_STARTED';
export const GET_ONE_CURRENT_WALLET_SUCCESS = 'GET_ONE_CURRENT_WALLET_SUCCESS';
export const GET_ONE_CURRENT_WALLET_FAILURE = 'GET_ONE_CURRENT_WALLET_FAILURE';

export const OPEN_STAKING_STARTED = 'OPEN_STAKING_STARTED';
export const OPEN_STAKING_SUCCESS = 'OPEN_STAKING_SUCCESS';
export const OPEN_STAKING_FAILURE = 'OPEN_STAKING_FAILURE';

export const GET_AVAILABLE_STAKING_STARTED = 'GET_AVAILABLE_STAKING_STARTED';
export const GET_AVAILABLE_STAKING_SUCCESS = 'GET_AVAILABLE_STAKING_SUCCESS';
export const GET_AVAILABLE_STAKING_FAILURE = 'GET_AVAILABLE_STAKING_FAILURE';

export const GET_STAKING_COMMISSION_STARTED = 'GET_STAKING_COMMISSION_STARTED';
export const GET_STAKING_COMMISSION_SUCCESS = 'GET_STAKING_COMMISSION_SUCCESS';
export const GET_STAKING_COMMISSION_FAILURE = 'GET_STAKING_COMMISSION_FAILURE';

export const GET_STAKING_AWARD_STARTED = 'GET_STAKING_AWARD_STARTED';
export const GET_STAKING_AWARD_SUCCESS = 'GET_STAKING_AWARD_SUCCESS';
export const GET_STAKING_AWARD_FAILURE = 'GET_STAKING_AWARD_FAILURE';

export const WITHDRAW_OMT_STAKING_STARTED = 'WITHDRAW_OMT_STAKING_STARTED';
export const WITHDRAW_OMT_STAKING_SUCCESS = 'WITHDRAW_OMT_STAKING_SUCCESS';
export const WITHDRAW_OMT_STAKING_FAILURE = 'WITHDRAW_OMT_STAKING_FAILURE';

export const CLOSE_OMT_STAKING_STARTED = 'CLOSE_OMT_STAKING_STARTED';
export const CLOSE_OMT_STAKING_SUCCESS = 'CLOSE_OMT_STAKING_SUCCESS';
export const CLOSE_OMT_STAKING_FAILURE = 'CLOSE_OMT_STAKING_FAILURE';

export const WITHDRAW_STAKING_STARTED = 'WITHDRAW_STAKING_STARTED';
export const WITHDRAW_STAKING_SUCCESS = 'WITHDRAW_STAKING_SUCCESS';
export const WITHDRAW_STAKING_FAILURE = 'WITHDRAW_STAKING_FAILURE';

export const EXCHANGE_STARTED = 'EXCHANGE_STARTED';
export const EXCHANGE_SUCCESS = 'EXCHANGE_SUCCESS';
export const EXCHANGE_FAILURE = 'EXCHANGE_FAILURE';

export const GET_EXCHANGE_HISTORY_STARTED = 'GET_EXCHANGE_HISTORY_STARTED';
export const GET_EXCHANGE_HISTORY_SUCCESS = 'GET_EXCHANGE_HISTORY_SUCCESS';
export const GET_EXCHANGE_HISTORY_FAILURE = 'GET_EXCHANGE_HISTORY_FAILURE';

export const GET_EXCHANGE_DATA_STARTED = 'GET_EXCHANGE_DATA_STARTED';
export const GET_EXCHANGE_DATA_SUCCESS = 'GET_EXCHANGE_DATA_SUCCESS';
export const GET_EXCHANGE_DATA_FAILURE = 'GET_EXCHANGE_DATA_FAILURE';

export const GET_WALLET_TRANSACTIONS_STARTED = 'GET_WALLET_TRANSACTIONS_STARTED';
export const GET_WALLET_TRANSACTIONS_SUCCESS = 'GET_WALLET_TRANSACTIONS_SUCCESS';
export const GET_WALLET_TRANSACTIONS_FAILURE = 'GET_WALLET_TRANSACTIONS_FAILURE';

export const SET_CUSTOM_SETTINGS_STARTED = 'SET_CUSTOM_SETTINGS_STARTED';
export const SET_CUSTOM_SETTINGS_SUCCESS = 'SET_CUSTOM_SETTINGS_SUCCESS';
export const SET_CUSTOM_SETTINGS_FAILURE = 'SET_CUSTOM_SETTINGS_FAILURE';

export const BUY_SUBSCRIBE_STARTED = 'BUY_SUBSCRIBE_STARTED';
export const BUY_SUBSCRIBE_SUCCESS = 'BUY_SUBSCRIBE_SUCCESS';
export const BUY_SUBSCRIBE_FAILURE = 'BUY_SUBSCRIBE_FAILURE';

export const GET_BOT_PROFIT_STARTED = 'GET_BOT_PROFIT_STARTED';
export const GET_BOT_PROFIT_SUCCESS = 'GET_BOT_PROFIT_SUCCESS';
export const GET_BOT_PROFIT_FAILURE = 'GET_BOT_PROFIT_FAILURE';

export const SET_BOT_PRO_PLUS_MODAL_STATUS = 'SET_BOT_PRO_PLUS_MODAL_STATUS';
export const SET_INDEX_FREEZE_MODAL_STATUS = 'SET_INDEX_FREEZE_MODAL_STATUS';
export const CLEAR_CABINET_STATE = 'CLEAR_CABINET_STATE';

export const GET_SUBSCRIBE_COMMISSION_STARTED = 'GET_SUBSCRIBE_COMMISSION_STARTED';
export const GET_SUBSCRIBE_COMMISSION_SUCCESS = 'GET_SUBSCRIBE_COMMISSION_SUCCESS';
export const GET_SUBSCRIBE_COMMISSION_FAILURE = 'GET_SUBSCRIBE_COMMISSION_FAILURE';

export const SET_GREETING_STARTED = 'SET_GREETING_STARTED';
export const SET_GREETING_SUCCESS = 'SET_GREETING_SUCCESS';
export const SET_GREETING_FAILURE = 'SET_GREETING_FAILURE';

export const GET_EXCHANGE_COMMISSION_STARTED = 'GET_EXCHANGE_COMMISSION_STARTED';
export const GET_EXCHANGE_COMMISSION_SUCCESS = 'GET_EXCHANGE_COMMISSION_SUCCESS';
export const GET_EXCHANGE_COMMISSION_FAILURE = 'GET_EXCHANGE_COMMISSION_FAILURE';

export const GET_OMT_TOKEN_STARTED = 'GET_OMT_TOKEN_STARTED';
export const GET_OMT_TOKEN_SUCCESS = 'GET_OMT_TOKEN_SUCCESS';
export const GET_OMT_TOKEN_FAILURE = 'GET_OMT_TOKEN_FAILURE';

export const FREEZE_TOKEN_STARTED = 'FREEZE_TOKEN_STARTED';
export const FREEZE_TOKEN_SUCCESS = 'FREEZE_TOKEN_SUCCESS';
export const FREEZE_TOKEN_FAILURE = 'FREEZE_TOKEN_FAILURE';

export const GET_FREEZE_COMMISSION_STARTED = 'GET_FREEZE_COMMISSION_STARTED';
export const GET_FREEZE_COMMISSION_SUCCESS = 'GET_FREEZE_COMMISSION_SUCCESS';
export const GET_FREEZE_COMMISSION_FAILURE = 'GET_FREEZE_COMMISSION_FAILURE';

export const GET_ACTIVE_FREEZE_STARTED = 'GET_ACTIVE_FREEZE_STARTED';
export const GET_ACTIVE_FREEZE_SUCCESS = 'GET_ACTIVE_FREEZE_SUCCESS';
export const GET_ACTIVE_FREEZE_FAILURE = 'GET_ACTIVE_FREEZE_FAILURE';

export const SET_FREEZE_REINVEST_STARTED = 'SET_FREEZE_REINVEST_STARTED';
export const SET_FREEZE_REINVEST_SUCCESS = 'SET_FREEZE_REINVEST_SUCCESS';
export const SET_FREEZE_REINVEST_FAILURE = 'SET_FREEZE_REINVEST_FAILURE';

export const FREEZE_WITHDRAW_STARTED = 'FREEZE_WITHDRAW_STARTED';
export const FREEZE_WITHDRAW_SUCCESS = 'FREEZE_WITHDRAW_SUCCESS';
export const FREEZE_WITHDRAW_FAILURE = 'FREEZE_WITHDRAW_FAILURE';

export const GET_FREEZE_TOTAL_STARTED = 'GET_FREEZE_TOTAL_STARTED';
export const GET_FREEZE_TOTAL_SUCCESS = 'GET_FREEZE_TOTAL_SUCCESS';
export const GET_FREEZE_TOTAL_FAILURE = 'GET_FREEZE_TOTAL_FAILURE';

export const CLEAR_INDEX_LIST = 'CLEAR_INDEX_LIST';
export const SET_SWAP_REQUEST_MODAL_STATUS = 'SET_SWAP_REQUEST_MODAL_STATUS';
export const SET_SWAP_REQUEST_CONFIRM_MODAL_STATUS = 'SET_SWAP_REQUEST_CONFIRM_MODAL_STATUS';
export const SET_SWAP_REQUEST_CANCEL_MODAL_STATUS = 'SET_SWAP_REQUEST_CANCEL_MODAL_STATUS';

export const GET_SWAP_MAIN_DATA_STARTED = 'GET_SWAP_MAIN_DATA_STARTED';
export const GET_SWAP_MAIN_DATA_SUCCESS = 'GET_SWAP_MAIN_DATA_SUCCESS';
export const GET_SWAP_MAIN_DATA_FAILURE = 'GET_SWAP_MAIN_DATA_FAILURE';

export const GET_SWAP_COMMISSION_STARTED = 'GET_SWAP_COMMISSION_STARTED';
export const GET_SWAP_COMMISSION_SUCCESS = 'GET_SWAP_COMMISSION_SUCCESS';
export const GET_SWAP_COMMISSION_FAILURE = 'GET_SWAP_COMMISSION_FAILURE';

export const CREATE_BUY_SWAP_REQUEST_STARTED = 'CREATE_BUY_SWAP_REQUEST_STARTED';
export const CREATE_BUY_SWAP_REQUEST_SUCCESS = 'CREATE_BUY_SWAP_REQUEST_SUCCESS';
export const CREATE_BUY_SWAP_REQUEST_FAILURE = 'CREATE_BUY_SWAP_REQUEST_FAILURE';

export const CREATE_SELL_SWAP_REQUEST_STARTED = 'CREATE_SELL_SWAP_REQUEST_STARTED';
export const CREATE_SELL_SWAP_REQUEST_SUCCESS = 'CREATE_SELL_SWAP_REQUEST_SUCCESS';
export const CREATE_SELL_SWAP_REQUEST_FAILURE = 'CREATE_SELL_SWAP_REQUEST_FAILURE';

export const GET_MY_SWAP_REQUESTS_STARTED = 'GET_MY_SWAP_REQUESTS_STARTED';
export const GET_MY_SWAP_REQUESTS_SUCCESS = 'GET_MY_SWAP_REQUESTS_SUCCESS';
export const GET_MY_SWAP_REQUESTS_FAILURE = 'GET_MY_SWAP_REQUESTS_FAILURE';

export const GET_ALL_SWAP_REQUESTS_STARTED = 'GET_ALL_SWAP_REQUESTS_STARTED';
export const GET_ALL_SWAP_REQUESTS_SUCCESS = 'GET_ALL_SWAP_REQUESTS_SUCCESS';
export const GET_ALL_SWAP_REQUESTS_FAILURE = 'GET_ALL_SWAP_REQUESTS_FAILURE';

export const CANCEL_MY_SWAP_REQUEST_STARTED = 'CANCEL_MY_SWAP_REQUEST_STARTED';
export const CANCEL_MY_SWAP_REQUEST_SUCCESS = 'CANCEL_MY_SWAP_REQUEST_SUCCESS';
export const CANCEL_MY_SWAP_REQUEST_FAILURE = 'CANCEL_MY_SWAP_REQUEST_FAILURE';

export const CLEAR_MY_SWAP_REQUEST_LIST = 'CLEAR_MY_SWAP_REQUEST_LIST';
export const CLEAR_ALL_SWAP_REQUEST_LIST = 'CLEAR_ALL_SWAP_REQUEST_LIST';

export const CONFIRM_BUY_SWAP_REQUEST_STARTED = 'CONFIRM_BUY_SWAP_REQUEST_STARTED';
export const CONFIRM_BUY_SWAP_REQUEST_SUCCESS = 'CONFIRM_BUY_SWAP_REQUEST_SUCCESS';
export const CONFIRM_BUY_SWAP_REQUEST_FAILURE = 'CONFIRM_BUY_SWAP_REQUEST_FAILURE';

export const CONFIRM_SELL_SWAP_REQUEST_STARTED = 'CONFIRM_SELL_SWAP_REQUEST_STARTED';
export const CONFIRM_SELL_SWAP_REQUEST_SUCCESS = 'CONFIRM_SELL_SWAP_REQUEST_SUCCESS';
export const CONFIRM_SELL_SWAP_REQUEST_FAILURE = 'CONFIRM_SELL_SWAP_REQUEST_FAILURE';

export const GET_STAKING_CLOSE_COMMISSION_STARTED = 'GET_STAKING_CLOSE_COMMISSION_STARTED';
export const GET_STAKING_CLOSE_COMMISSION_SUCCESS = 'GET_STAKING_CLOSE_COMMISSION_SUCCESS';
export const GET_STAKING_CLOSE_COMMISSION_FAILURE = 'GET_STAKING_CLOSE_COMMISSION_FAILURE';

export const GET_PROMOTIONS_NY_MAIN_DATA_STARTED = 'GET_PROMOTIONS_NY_MAIN_DATA_STARTED';
export const GET_PROMOTIONS_NY_MAIN_DATA_SUCCESS = 'GET_PROMOTIONS_NY_MAIN_DATA_SUCCESS';
export const GET_PROMOTIONS_NY_MAIN_DATA_FAILURE = 'GET_PROMOTIONS_NY_MAIN_DATA_FAILURE';

export const NY_SPIN_STARTED = 'NY_SPIN_STARTED';
export const NY_SPIN_SUCCESS = 'NY_SPIN_SUCCESS';
export const NY_SPIN_FAILURE = 'NY_SPIN_FAILURE';

export const GET_NY_REWARD_HISTORY_STARTED = 'GET_NY_REWARD_HISTORY_STARTED';
export const GET_NY_REWARD_HISTORY_SUCCESS = 'GET_NY_REWARD_HISTORY_SUCCESS';
export const GET_NY_REWARD_HISTORY_FAILURE = 'GET_NY_REWARD_HISTORY_FAILURE';

export const SET_NY_COIN_BALANCE = 'SET_NY_COIN_BALANCE';

export const GET_NY_COINS_PACK_STARTED = 'GET_NY_COINS_PACK_STARTED';
export const GET_NY_COINS_PACK_SUCCESS = 'GET_NY_COINS_PACK_SUCCESS';
export const GET_NY_COINS_PACK_FAILURE = 'GET_NY_COINS_PACK_FAILURE';

export const GET_NY_COINS_COMMISSION_STARTED = 'GET_NY_COINS_COMMISSION_STARTED';
export const GET_NY_COINS_COMMISSION_SUCCESS = 'GET_NY_COINS_COMMISSION_SUCCESS';
export const GET_NY_COINS_COMMISSION_FAILURE = 'GET_NY_COINS_COMMISSION_FAILURE';

export const BUY_NY_COINS_STARTED = 'BUY_NY_COINS_STARTED';
export const BUY_NY_COINS_SUCCESS = 'BUY_NY_COINS_SUCCESS';
export const BUY_NY_COINS_FAILURE = 'BUY_NY_COINS_FAILURE';

export const GET_ACADEMY_MAIN_DATA_STARTED = 'GET_ACADEMY_MAIN_DATA_STARTED';
export const GET_ACADEMY_MAIN_DATA_SUCCESS = 'GET_ACADEMY_MAIN_DATA_SUCCESS';
export const GET_ACADEMY_MAIN_DATA_FAILURE = 'GET_ACADEMY_MAIN_DATA_FAILURE';

export const SET_ACADEMY_BUY_MODAL_STATUS = 'SET_ACADEMY_BUY_MODAL_STATUS';

export const GET_ACADEMY_BUY_COMMISSION_STARTED = 'GET_ACADEMY_BUY_COMMISSION_STARTED';
export const GET_ACADEMY_BUY_COMMISSION_SUCCESS = 'GET_ACADEMY_BUY_COMMISSION_SUCCESS';
export const GET_ACADEMY_BUY_COMMISSION_FAILURE = 'GET_ACADEMY_BUY_COMMISSION_FAILURE';

export const ACADEMY_BUY_STARTED = 'ACADEMY_BUY_STARTED';
export const ACADEMY_BUY_SUCCESS = 'ACADEMY_BUY_SUCCESS';
export const ACADEMY_BUY_FAILURE = 'ACADEMY_BUY_FAILURE';

export const GET_ACADEMY_ALL_LESSONS_STARTED = 'GET_ACADEMY_ALL_LESSONS_STARTED';
export const GET_ACADEMY_ALL_LESSONS_SUCCESS = 'GET_ACADEMY_ALL_LESSONS_SUCCESS';
export const GET_ACADEMY_ALL_LESSONS_FAILURE = 'GET_ACADEMY_ALL_LESSONS_FAILURE';

export const GET_ACADEMY_CURRENT_LESSON_STARTED = 'GET_ACADEMY_CURRENT_LESSON_STARTED';
export const GET_ACADEMY_CURRENT_LESSON_SUCCESS = 'GET_ACADEMY_CURRENT_LESSON_SUCCESS';
export const GET_ACADEMY_CURRENT_LESSON_FAILURE = 'GET_ACADEMY_CURRENT_LESSON_FAILURE';

export const SET_ACADEMY_TEST_WARNING_MODAL_STATUS = 'SET_ACADEMY_TEST_WARNING_MODAL_STATUS';
export const SET_ACADEMY_TEST_RESULT_MODAL_STATUS = 'SET_ACADEMY_TEST_RESULT_MODAL_STATUS';

export const GET_ACADEMY_TEST_QUESTION_STARTED = 'GET_ACADEMY_TEST_QUESTION_STARTED';
export const GET_ACADEMY_TEST_QUESTION_SUCCESS = 'GET_ACADEMY_TEST_QUESTION_SUCCESS';
export const GET_ACADEMY_TEST_QUESTION_FAILURE = 'GET_ACADEMY_TEST_QUESTION_FAILURE';

export const SET_ACADEMY_TEST_ANSWER_STARTED = 'SET_ACADEMY_TEST_ANSWER_STARTED';
export const SET_ACADEMY_TEST_ANSWER_SUCCESS = 'SET_ACADEMY_TEST_ANSWER_SUCCESS';
export const SET_ACADEMY_TEST_ANSWER_FAILURE = 'SET_ACADEMY_TEST_ANSWER_FAILURE';

export const SET_ACADEMY_START_LEARNING_STARTED = 'SET_ACADEMY_START_LEARNING_STARTED';
export const SET_ACADEMY_START_LEARNING_SUCCESS = 'SET_ACADEMY_START_LEARNING_SUCCESS';
export const SET_ACADEMY_START_LEARNING_FAILURE = 'SET_ACADEMY_START_LEARNING_FAILURE';