import React from "react";
import Styles from './styles.module.scss'
import {useTranslation} from "react-i18next";




const AcademyMain = () => {
    const { t } = useTranslation();

    return (
        <section className={`${Styles.container} ${Styles.honeeCard}`}>
            <div className={`${Styles.honeeCardBody}`}>
                <div className={`${Styles.text} ${Styles.titleWSF}`}>{t('Добро пожаловать в')} <span className={`${Styles.titleWSF}`}>OMT {t('Академию')}</span></div>
            </div>
        </section>
    );
};

export default AcademyMain;
