import React, {useEffect} from "react";
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';
import Styles from './styles.module.scss'
import {useDispatch, useSelector} from "react-redux";
import {
    academyBuy,
    getAcademyBuyCommission,
    setAcademyBuyModalStatus
} from "../../store/cabinet/action";

import {useTranslation} from "react-i18next";
import {valueFixer} from "../../helpers/valueFixer";


const AcademyBuyModal = () => {
    const dispatch = useDispatch();
    const { t } = useTranslation();

    const academyBuyModalStatus = useSelector(store => store.cabinet.academyBuyModalStatus);
    const academyBuyCommission = useSelector(store => store.cabinet.academyBuyCommission);
    const academyMainData = useSelector(store => store.cabinet.academyMainData);


    const isCommissionSuccess = () =>{
        return academyBuyCommission ? Number(academyBuyCommission?.wallet?.amount) >= Number(academyBuyCommission?.commission) : false
    }

    const isBalanceSuccess = () =>{
        return academyMainData?.wallet?.amount && academyBuyCommission ? Number(academyMainData?.wallet?.amount) >= Number(academyBuyCommission?.amount * 1) : false
    }

    const isBuySuccess = () =>{
        return isCommissionSuccess() && isBalanceSuccess() && academyMainData
    }

    const closeModal = () =>{
        dispatch(setAcademyBuyModalStatus(false))
    }

    useEffect(() => {
        if (academyBuyModalStatus){
            dispatch(getAcademyBuyCommission());
        }
    }, [academyBuyModalStatus, dispatch]);

    return (
        <Popup
            open={academyBuyModalStatus}
            modal
            nested
            closeOnDocumentClick
            onClose={closeModal}
        >
            <div className={`${Styles.modalContainer}`}>
                <div className={`${Styles.modalCloseBtn}`} onClick={()=>{closeModal()}}>
                    <div/>
                    <div/>
                </div>
                <div className={`${Styles.modalContentWrap}`}>
                    <div className={`${Styles.titleWSF}`}>{t('Покупка курса')}</div>
                    <div className={`${Styles.priceRow}`}>
                        <div className={`${Styles.priceNote}`}>{t('Стоимость курса')}:</div>
                        <div className={`${Styles.price}`}>{academyBuyCommission?.amount ? valueFixer(academyBuyCommission?.amount * 1, 6) : '0'} INDEX
                            OMT20
                        </div>
                    </div>
                    <div className={`${Styles.textRow}`}>{t('Стоимость курса составляет 50$ в эквиваленте INDEX OMT20')}</div>
                    <div className={`${Styles.balanceRow}`}>
                        <div className={`${Styles.balanceNote}`}>{t('Ваш баланс')}:</div>
                        <div className={`${Styles.balance} ${isBalanceSuccess() ? Styles.balanceSuccess : null}`}>{academyMainData?.wallet?.amount * 1} INDEX OMT20</div>
                    </div>
                    <div className={`${Styles.commission}`}>{t('Комиссия сети:')} <span className={Styles.colorOrange}>{academyBuyCommission?.commission ? academyBuyCommission?.commission * 1 : '0'} BNB</span> {academyBuyCommission?.wallet?.amount || Number(academyBuyCommission?.wallet?.amount) === 0 ? isCommissionSuccess() ? <span className={`${Styles.colorGreen}`}>({t('Достаточно')})</span> : <span className={`${Styles.colorRed}`}>({t('Недостаточно')})</span> : null}</div>
                    <div
                        className={`${isBuySuccess() ? Styles.btn : Styles.cabinetBtnDisable}`}
                        onClick={()=>{
                            if (isBuySuccess()){
                                dispatch(academyBuy(academyMainData.course_id, closeModal));
                            }
                        }}
                    >
                        {t('Купить')}
                    </div>
                </div>
            </div>
        </Popup>
    );
};

export default AcademyBuyModal;
