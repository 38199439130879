import React from "react";
import Styles from './styles.module.scss'
import {useTranslation} from "react-i18next";
import {useSelector} from "react-redux";
import {useNavigate} from "react-router-dom";




const AcademyStatistic = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const academyMainData = useSelector(store => store.cabinet.academyMainData);

    return (
        <section className={`${Styles.container} ${Styles.honeeCard}`}>
            <div className={`${Styles.honeeCardBody}`}>
                <div className={`${Styles.statisticWrap}`}>
                    <div className={`${Styles.statisticTitle} ${Styles.titleWSF}`}><span className={`${Styles.titleWSF}`}>OMT</span> {t('Академия')}</div>
                    <div className={`${Styles.statisticBox}`}>
                        <div className={`${Styles.statisticBoxItem}`}>
                            <div className={`${Styles.statisticBoxItemTitle} ${Styles.titleWSF}`}>{t('Уроков пройдено')}:</div>
                            <div className={`${Styles.statisticBoxItemValue}`}>{academyMainData?.lessons_complete ? academyMainData?.lessons_complete * 1 : '0'}</div>
                        </div>
                        <div className={`${Styles.statisticBoxItem}`}>
                            <div className={`${Styles.statisticBoxItemTitle} ${Styles.titleWSF}`}>{t('БАЛЛОВ ЗАРАБОТАНО')}:</div>
                            <div className={`${Styles.statisticBoxItemValue}`}>{academyMainData?.points ? academyMainData?.points * 1 : '0'}</div>
                        </div>
                    </div>
                </div>
                <div className={`${Styles.noteWrap}`}>
                    <div className={`${Styles.noteItem}`}>{t('Добро пожаловать в ваш личный кабинет')} <span>{t('Академии OMT')}!</span> {t('Здесь вы найдёте весь список уроков, увидите, сколько занятий уже завершено, и сможете следить за тем, как пополняется ваш баланс баллов.')}</div>
                    <div className={`${Styles.noteItem}`}>{t('С каждым уроком вы укрепляете своё понимание рынка и повышаете свой «инвестиционный» статус. Чем выше ваши результаты, тем больше шансов использовать Launchpad для успешного старта в новых, перспективных проектах.')}</div>
                </div>
                <div className={`${Styles.btn}`} onClick={() => {navigate('/news/_omt_academy_your_way_to_safe_and_successful_investing__n14')}}>{t('Подробнее')}</div>
            </div>
        </section>
    );
};

export default AcademyStatistic;
