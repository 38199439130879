import React, {useEffect, useState} from "react";
import Styles from './styles.module.scss'
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {useParams} from "react-router-dom";
import {getAcademyCurrentLesson, getAcademyTestQuestion, setAcademyTestAnswer} from "../../store/cabinet/action";
import AcademyCurrentTestTimer from "../academyCurrentTestTimer";




const AcademyCurrentTest = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    let {courceId, lessonId} = useParams();

    const academyCurrentLesson = useSelector(store => store.cabinet.academyCurrentLesson);
    const academyCurrentTestQuestion = useSelector(store => store.cabinet.academyCurrentTestQuestion);

    const [currentAnswerId, setCurrentAnswerId] = useState(false);

    const timerEndHandler = () =>{
        if (academyCurrentLesson?.test_status === 'active'){
            dispatch(getAcademyCurrentLesson(courceId, lessonId));
        }
    }


    useEffect(() => {
        if (academyCurrentLesson?.test_status === 'active'){
            dispatch(getAcademyTestQuestion(courceId, lessonId));
        }
    }, [academyCurrentLesson, courceId, dispatch, lessonId]);

    return (
        <section className={`${Styles.container} ${Styles.honeeCard}`}>
            <div className={`${Styles.honeeCardBody}`}>
                <div
                    className={`${Styles.lessonInfo}`}
                    style={{backgroundImage: `url('${academyCurrentLesson?.picture}')`}}
                >
                    <div className={`${Styles.lessonInfoNumber} ${Styles.titleWSF}`}>{t('Урок')} {academyCurrentLesson?.order}.</div>
                    <div className={`${Styles.lessonInfoTitle} ${Styles.titleWSF}`}>{academyCurrentLesson?.title}</div>
                    <div className={`${Styles.lessonInfoAbsTimer} ${academyCurrentLesson?.test_status === 'active' ? Styles.lessonInfoAbsTimerActive : null}`}>
                        <AcademyCurrentTestTimer targetDate={academyCurrentLesson?.test_ends_at} onComplete={timerEndHandler}/>
                    </div>
                </div>
                <div className={`${Styles.testWrap}`}>
                    <div className={`${Styles.testTitle} ${Styles.titleWSF}`}>{t('финальный тест')}</div>
                    <div className={`${Styles.testBox}`}>
                        <div className={`${Styles.question}`}>{t('Вопрос')} {academyCurrentTestQuestion?.question?.order}/{academyCurrentLesson?.questions_count}: {academyCurrentTestQuestion?.question?.question}</div>
                        <div className={`${Styles.answerWrap}`}>
                            {academyCurrentTestQuestion?.question?.answers ? academyCurrentTestQuestion?.question?.answers.map((item, index)=>{
                                return (
                                    <div
                                        className={`${Styles.answerItem}`}
                                        key={'question' + index}
                                        onClick={()=>{setCurrentAnswerId(item.id)}}
                                    >
                                        <div className={`${Styles.customCheckBox} ${item.id === currentAnswerId ? Styles.customCheckBoxActive : null}`}>
                                            <div className={`${Styles.customCheck}`}/>
                                        </div>
                                        <div className={`${Styles.answer}`}>{item.title}</div>
                                    </div>
                                )
                            }) : null}
                        </div>
                        <div
                            className={`${currentAnswerId ? Styles.btn : Styles.cabinetBtnDisable} ${Styles.answerBtn}`}
                            onClick={()=>{
                                if (currentAnswerId){
                                    dispatch(setAcademyTestAnswer(courceId, lessonId, academyCurrentTestQuestion?.question?.id, academyCurrentTestQuestion?.is_last, {answer: currentAnswerId}));
                                    setCurrentAnswerId(false);
                                }
                            }}
                        >
                            {t('Подтвердить ответ')}
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default AcademyCurrentTest;


