import React from "react";
import Styles from './styles.module.scss'
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {useNavigate, useParams} from "react-router-dom";
import DOMPurify from "dompurify";

import backIcon from '../../theme/assets/svg/h2_academy_back_icon.svg';
import {setAcademyStartLearning, setAcademyTestWarningModalStatus} from "../../store/cabinet/action";




const AcademyCurrentLesson = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    let {courceId, lessonId} = useParams();

    const academyCurrentLesson = useSelector(store => store.cabinet.academyCurrentLesson);

    const startLearningHandler = () =>{
        navigate('/academy');
    }

    return (
        <section className={`${Styles.container} ${Styles.honeeCard}`}>
            <div className={`${Styles.honeeCardBody}`}>
                <div className={`${Styles.lessonWrap}`}>
                    <div className={`${Styles.lessonTitleWrap}`}>
                        <div className={`${Styles.backBtn}`} onClick={()=>{navigate('/academy')}}>
                            <div className={`${Styles.backIcon}`}>
                                <img src={backIcon} alt={t('Назад')}/>
                            </div>
                            <div className={`${Styles.backText} ${Styles.titleWSF}`}>{t('Назад')}</div>
                        </div>
                        {academyCurrentLesson.order !== 1 ?
                            <div className={`${Styles.lessonTitle} ${Styles.titleWSF}`}>
                                <span className={`${Styles.titleWSF}`}>{t('Урок')} {academyCurrentLesson?.order}.</span> {academyCurrentLesson?.title}
                            </div> :
                            <div className={`${Styles.lessonTitle} ${Styles.titleWSF}`}>
                                <span className={`${Styles.titleWSF}`}>{t('Вводный урок')}.</span> {academyCurrentLesson?.title}
                            </div>
                        }
                    </div>
                    <div
                        className={`${Styles.lessonContentWrap}`}
                        dangerouslySetInnerHTML={{
                            __html: DOMPurify.sanitize(academyCurrentLesson.content).replace(/<a\s+(?:[^>]*?\s+)?href=(["'])(.*?)\1/gi, '<a target="_blank" href=$1$2$1')
                        }}
                    />
                    {academyCurrentLesson.test_exists ? academyCurrentLesson.status === 'completed' ?
                        <div className={`${Styles.lessonTestWrap}`}>
                            <div className={`${Styles.lessonTestTitle}`}>{t('Тест завершен, ваш результат')}: {academyCurrentLesson.correct_answers}/{academyCurrentLesson.questions_count}</div>
                            <div className={`${Styles.lessonTestNote}`}>{t('Отличная работа, продолжайте двигаться вперед! Следующие уроки помогут вам углубить знания и стать увереннее в мире криптовалют.')}</div>
                            <div className={`${Styles.btn} ${Styles.lessonTestBtn}`} onClick={() => {navigate('/academy')}}>{t('Вернуться на главную')}</div>
                        </div> :
                        <div className={`${Styles.lessonTestWrap}`}>
                            <div className={`${Styles.lessonTestNote}`}>{t('Поздравляем с завершением урока! Теперь вы можете пройти финальный тест, чтобы проверить и закрепить свои знания. Внимание: попытка только одна, поэтому сосредоточьтесь и ответьте на вопросы максимально точно. Удачи!')}🚀</div>
                            <div className={`${Styles.btn} ${Styles.lessonTestBtn}`} onClick={() => {dispatch(setAcademyTestWarningModalStatus(true))}}>{t('Пройти тест')}</div>
                        </div> :
                        academyCurrentLesson.status === 'completed' ? null :
                        <div className={`${Styles.lessonTestWrap}`}>
                            <div className={`${Styles.btn} ${Styles.lessonTestBtn}`} onClick={() => {dispatch(setAcademyStartLearning(courceId, lessonId, startLearningHandler))}}>{t('Начать обучение')}</div>
                        </div>
                    }
                </div>
            </div>
        </section>
    );
};

export default AcademyCurrentLesson;
