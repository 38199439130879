import React, {useEffect} from "react";
import Styles from './styles.module.scss'
import {useSelector, useDispatch} from "react-redux";
import {useNavigate, useParams} from "react-router-dom";
import Seo from "../../components/_UI/seo";
import {useTranslation} from "react-i18next";
import {getAcademyCurrentLesson} from "../../store/cabinet/action";
import AcademyCurrentLesson from "../../components/academyCurrentLesson";
import AcademyTestWarningModal from "../../components/academyTestWarningModal";
import AcademyCurrentTest from "../../components/academyCurrentTest";
import i18n from "i18next";
import AcademyTestResultModal from "../../components/academyTestResultModal";


const AcademyLessonPage = () => {
    const navigate = useNavigate();
    const { t } = useTranslation();
    const dispatch = useDispatch();
    let {courceId, lessonId} = useParams();

    const authStatus = useSelector(store => store.main.authorizationStatus);
    const academyCurrentLesson = useSelector(store => store.cabinet.academyCurrentLesson);

    useEffect(()=>{
        if (authStatus.authorization){
            if (!authStatus.phrase){
                navigate('/confirmation');
            } else if (!authStatus.server){
                navigate('/server');
            }
        } else {
            navigate('/sign-in');
        }

    },[authStatus, navigate])
    
    useEffect(()=>{
        dispatch(getAcademyCurrentLesson(courceId, lessonId));
    },[courceId, dispatch, lessonId, i18n.language]) // eslint-disable-line
    
    return (
        <section className={`${Styles.container}`}>
            <Seo title={`OMT ${t('Академия')}`}/>
            <div className={`${Styles.contentWrap}`}>
                {
                    academyCurrentLesson.test_status === 'active' ?
                    <>
                        <AcademyCurrentTest/>
                    </> :
                    <>
                        <AcademyCurrentLesson/>
                        <AcademyTestWarningModal/>
                        <AcademyTestResultModal/>
                    </>
                }
            </div>
        </section>
    );
};

export default AcademyLessonPage;