import React, {useEffect} from "react";
import Styles from './styles.module.scss'
import {useSelector, useDispatch} from "react-redux";
import {useNavigate} from "react-router-dom";
import Seo from "../../components/_UI/seo";
import {useTranslation} from "react-i18next";
import AcademyMain from "../../components/academyMain";
import AcademyMozaik from "../../components/academyMozaik";
import {getAcademyAllLessons, getAcademyMainData} from "../../store/cabinet/action";
import AcademyBuyModal from "../../components/academyBuyModal";
import AcademyStatistic from "../../components/academyStatistic";
import AcademyLessonsPreview from "../../components/academyLessonsPreview";


const AcademyPage = () => {
    const navigate = useNavigate();
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const authStatus = useSelector(store => store.main.authorizationStatus);
    const academyMainData = useSelector(store => store.cabinet.academyMainData);

    useEffect(()=>{
        if (authStatus.authorization){
            if (!authStatus.phrase){
                navigate('/confirmation');
            } else if (!authStatus.server){
                navigate('/server');
            }
        } else {
            navigate('/sign-in');
        }

    },[authStatus, navigate])

    useEffect(()=>{
        dispatch(getAcademyMainData());
    },[dispatch])

    useEffect(()=>{
        if (academyMainData?.lessons_status === 'bought'){
            dispatch(getAcademyAllLessons(academyMainData?.course_id))
        }
    },[academyMainData, dispatch])


    return (
        <section className={`${Styles.container}`}>
            <Seo title={`OMT ${t('Академия')}`}/>
            <div className={`${Styles.contentWrap}`}>
                {academyMainData?.lessons_status === 'bought' ?
                    <>
                        <AcademyStatistic/>
                        <AcademyLessonsPreview/>
                    </> :
                    <>
                        <AcademyMain/>
                        <AcademyMozaik/>
                        <AcademyBuyModal/>
                    </>
                }
            </div>
        </section>
    );
};

export default AcademyPage;