import React, {useEffect, useState} from "react";
import Styles from './styles.module.scss'
import {useTranslation} from "react-i18next";




const AcademyCurrentTestTimer = ({ targetDate, onComplete }) => {
    const { t } = useTranslation();
    const calculateTimeLeft = () => {
        const difference = new Date(targetDate) - new Date();
        if (difference > 0) {
          return {
            minutes: Math.floor((difference / 1000 / 60) % 60),
            seconds: Math.floor((difference / 1000) % 60),
          };
        }
        return null;
      };

    const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

    const formatTime = (time) => (time < 10 ? `0${time}` : time);


    useEffect(() => {
        if (!timeLeft) {
          onComplete && onComplete();
          return;
        }

        const timer = setInterval(() => {
          setTimeLeft(calculateTimeLeft());
        }, 1000);

        return () => clearInterval(timer);
      }, [timeLeft]); // eslint-disable-line

    return (
        <section className={`${Styles.timerWrap}`}>
            <div className={`${Styles.timerTitle}`}>{t('Время на выполнение')}:</div>
            <div className={`${Styles.timerBox}`}>
                {timeLeft ? (
                    <span>
                      {formatTime(timeLeft.minutes)}:{formatTime(timeLeft.seconds)}
                    </span>
                ) : (
                    <span>{t('Время вышло!')}</span>
                )}
            </div>
        </section>
    );
};

export default AcademyCurrentTestTimer;
