import React from "react";
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';
import Styles from './styles.module.scss'
import {useDispatch, useSelector} from "react-redux";
import {
    setAcademyTestResultModalStatus,
} from "../../store/cabinet/action";

import {useTranslation} from "react-i18next";
import {useNavigate} from "react-router-dom";


const AcademyTestResultModal = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { t } = useTranslation();

    const academyTestResultModalStatus = useSelector(store => store.cabinet.academyTestResultModalStatus);

    const closeModal = () =>{
        navigate('/academy');
        dispatch(setAcademyTestResultModalStatus({status: false, data: false}));
    }

    return (
        <Popup
            open={academyTestResultModalStatus?.status}
            modal
            nested
            closeOnDocumentClick
            onClose={closeModal}
        >
            <div className={`${Styles.modalContainer}`}>
                <div className={`${Styles.modalCloseBtn}`} onClick={()=>{closeModal()}}>
                    <div/>
                    <div/>
                </div>
                <div className={`${Styles.modalContentWrap}`}>
                    <div className={`${Styles.modalContentWrapTitle}`}>{t('Поздравляем с завершением теста')}!</div>
                    <div className={`${Styles.modalContentWrapSubTitle}`}>{t('Ваш результат')}:</div>
                    <div className={`${Styles.modalContentWrapResult}`}>{academyTestResultModalStatus?.data?.correct === 0 ? '0' : academyTestResultModalStatus?.data?.correct}/{academyTestResultModalStatus?.data?.total}</div>
                    <div className={`${Styles.btn}`} onClick={()=>{closeModal()}}>{t('ОК')}</div>
                </div>
            </div>
        </Popup>
    );
};

export default AcademyTestResultModal;
