import React from "react";
import Styles from './styles.module.scss';
import {useSelector} from "react-redux";
import EmptyValues from "../_UI/emtyValues";

import arrow from '../../theme/assets/svg/omt_bot_profit_arrow.svg';
import {useTranslation} from "react-i18next";



const AmbassadorsProfit = () => {
    const ambassadorGraphCorrectData = useSelector(store => store.cabinet.ambassadorGraphCorrectData);

    const userData = useSelector(store => store.main.userData);

    const { t } = useTranslation();

    const heightHandler = (value) =>{
        if (value === 0){
            return 7
        } else {
            return (100 / ambassadorGraphCorrectData.max) * value
        }
    }

    return (
        <section className={`${Styles.container} ${Styles.honeeCard}`}>
            <div className={`${Styles.honeeCardBody}`}>
                <div className={`${Styles.cardBody}`}>
                    <div className={`${Styles.titleWSF}`}>{t('Недельная активность')}</div>
                    <div className={`${Styles.contentBox}`} style={{alignContent: `${ambassadorGraphCorrectData ? 'end' : 'center'}`}}>
                        {
                            ambassadorGraphCorrectData ? ambassadorGraphCorrectData.data.map((item, index)=>{
                                return (
                                    <div className={`${Styles.contentBoxItem}`} key={'botProfit' + index}>
                                        <div className={`${Styles.contentBoxItemBar}`} style={{height: `${(ambassadorGraphCorrectData.max === 0 ? '7' : heightHandler(item.value))}%`}}>
                                            <div className={`${Styles.contentBoxItemBarValue}`}>
                                                {item.value.toFixed(2) * 1}
                                                <img src={arrow} alt={item.value}/>
                                            </div>
                                        </div>
                                        <div className={`${Styles.contentBoxItemDay} ${userData?.is_influencer ? Styles.contentBoxItemDayInfluencer : ''}`}>{item.day.toLowerCase()}</div>
                                    </div>
                                )
                            } ) : <EmptyValues text={t('Запустите бота для просмотра статистики')} withIcon={false} withBtn={false}/>
                        }
                    </div>
                </div>
            </div>
        </section>
    );
};

export default AmbassadorsProfit;
